import configs from '@/configs';
import { EventType } from '@/constants/eventType';
import { localStorage } from '@/helpers/localStorage';
import request from '@/utils/request';

export function updateClickEventProduct(params: {
  id: string;
  items: any;
  actionedAt: number;
}) {
  return request(
    `${configs.api}/backend/events`,
    'POST',
    {
      data: JSON.stringify({
        uuid: localStorage.get(window, 'uuid'),
        requestId: localStorage.get(window, 'requestId'),
        eventType: EventType.CLICK_PRODUCT,
        event: {
          items: params.items,
          suggestionUUID: params.id,
          actionedAt: params.actionedAt,
        },
      }),
    },
    true
  );
}
