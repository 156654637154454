import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import amazoneService from 'src/services/amazon';

import chatGPTService from '@/services/chatGPT';

import { fetchAmazoneItemsFailure, fetchAmazoneItemsSuccess } from './actions';
import { LOAD_AMAZONE_ITEMS } from './actionTypes';

function* fetchAmazoneItemsSaga(action: any) {
  try {
    const response: { result: any } = yield call(
      amazoneService.requests.getItemsFromAmazon,
      action.data.suggest
    );
    const answeredAt = Date.now();
    yield fork(chatGPTService.requests.updateEventItem, {
      error: '',
      id: action.data.id,
      items: response.result,
      answeredAt,
      requestedAt: answeredAt,
    });

    yield put(
      fetchAmazoneItemsSuccess({
        suggests: response.result,
        totalSuggests: action.totalSuggests,
      })
    );
  } catch (error: any) {
    const answeredAt = Date.now();
    yield put(
      fetchAmazoneItemsFailure({
        error: error.message || 'Something went wrong',
      })
    );
    yield fork(chatGPTService.requests.updateEventItem, {
      error: error.message,
      id: action.data.id,
      items: [],
      answeredAt,
      requestedAt: answeredAt,
    });
    // fetchUsersFailure({ error: error.message || 'Something went wrong' })
  }
}

function* suggestionsSaga() {
  yield all([takeEvery(LOAD_AMAZONE_ITEMS, fetchAmazoneItemsSaga)]);
}

export default suggestionsSaga;
