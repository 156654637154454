import {
  CLEAR_CURRENT_LIST,
  CLEAR_ERROR_COUNT,
  LOAD_AMAZONE_ITEMS,
  LOADED_AMAZONE_ITEMS_FAILURE,
  LOADED_AMAZONE_ITEMS_SUCCESS,
  UPDATE_CURRENT_LIST,
} from './actionTypes';
import type {
  FetchAmazoneItemsFailure,
  FetchAmazoneItemsFailurePayload,
  FetchAmazoneItemsRequest,
  FetchAmazoneItemsSuccess,
  FetchAmazoneItemsSuccessPayload,
} from './types';

export const fetchAmazonItemsRequest = (
  data: any
): FetchAmazoneItemsRequest => ({
  type: LOAD_AMAZONE_ITEMS,
  data,
});

export const fetchAmazoneItemsSuccess = (
  payload: FetchAmazoneItemsSuccessPayload
): FetchAmazoneItemsSuccess => ({
  type: LOADED_AMAZONE_ITEMS_SUCCESS,
  payload,
});

export const fetchAmazoneItemsFailure = (
  payload: FetchAmazoneItemsFailurePayload
): FetchAmazoneItemsFailure => ({
  type: LOADED_AMAZONE_ITEMS_FAILURE,
  payload,
});
export const updateCurrentList = (data: any) => {
  return {
    type: UPDATE_CURRENT_LIST,
    data,
  };
};
export const clearCurrentList = (data: boolean) => {
  return {
    type: CLEAR_CURRENT_LIST,
    data,
  };
};
export const clearErrorCount = () => {
  return {
    type: CLEAR_ERROR_COUNT,
  };
};
