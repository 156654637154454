import {
  LOAD_USERS,
  LOADED_USERS_FAILURE,
  LOADED_USERS_SUCCESS,
  SET_CURRENT_STEP,
  SET_TEXT_PROMPT,
} from './actionTypes';
import type {
  FetchUsersFailure,
  FetchUsersFailurePayload,
  FetchUsersRequest,
  FetchUsersSuccess,
  FetchUsersSuccessPayload,
  ITextPrompt,
  SetCurrentStep,
  SetTextPrompt,
} from './types';

export const fetchUsersRequest = (isSSR = false): FetchUsersRequest => ({
  type: LOAD_USERS,
  isSSR,
});

export const fetchUsersSuccess = (
  payload: FetchUsersSuccessPayload
): FetchUsersSuccess => ({
  type: LOADED_USERS_SUCCESS,
  payload,
});

export const fetchUsersFailure = (
  payload: FetchUsersFailurePayload
): FetchUsersFailure => ({
  type: LOADED_USERS_FAILURE,
  payload,
});

export const setTextPrompt = (textPrompt: ITextPrompt): SetTextPrompt => ({
  type: SET_TEXT_PROMPT,
  textPrompt,
});

export const setCurrentStep = (step: number): SetCurrentStep => ({
  type: SET_CURRENT_STEP,
  step,
});
