import { HYDRATE } from 'next-redux-wrapper';
import type { AnyAction } from 'redux';

import type { AmazoneItemsState } from '@/services/amazon/redux/types';

import {
  CLEAR_CURRENT_LIST,
  CLEAR_ERROR_COUNT,
  LOAD_AMAZONE_ITEMS,
  LOADED_AMAZONE_ITEMS_FAILURE,
  LOADED_AMAZONE_ITEMS_SUCCESS,
  UPDATE_CURRENT_LIST,
} from './actionTypes';

// The initial state of the App
const initialStates: AmazoneItemsState = {
  loaded: false,
  error: false,
  total: 0,
  limit: 0,
  keyword: '',
  count: 0,
  list: [],
  tempList: [],
  isSSR: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function reducer(state = initialStates, action: AnyAction) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.users.isSSR
        ? { ...action.payload.users }
        : { ...state };
    case UPDATE_CURRENT_LIST:
      if (action.data) {
        return {
          ...state,
          list: action.data,
          count: 0,
          tempList: [],
          loaded: true,
        };
      }
      return { ...state, loaded: true, tempList: [] };
    case LOAD_AMAZONE_ITEMS:
      return { ...state, loaded: false };
    case LOADED_AMAZONE_ITEMS_SUCCESS: {
      const clonedTempList = [...state.tempList];
      if (action.payload.suggests) {
        clonedTempList.push(action.payload.suggests);
        return {
          ...state,
          loaded: false,
          count: state.count + 1,
          tempList: clonedTempList,
          error: null,
        };
      }
      return state;
    }
    case LOADED_AMAZONE_ITEMS_FAILURE: {
      return {
        ...state,
        count: state.count + 1,
      };
    }
    case CLEAR_CURRENT_LIST:
      return { ...state, list: [], loaded: action.data };
    case CLEAR_ERROR_COUNT:
      if (state.count > 0) {
        return { ...state, list: [], count: 0 };
      }
      return state;
    default:
      return state;
  }
}
