import configs from '@/configs';
import { localStorage } from '@/helpers/localStorage';
import request from '@/utils/request';

export function updateItemData(params: {
  id: string;
  error: null;
  products: any;
  requestedAt: number;
  answeredAt: number;
}) {
  return request(
    `${configs.backendUrl}/events`,
    'POST',
    {
      data: JSON.stringify({
        uuid: localStorage.get(window, 'uuid'),
        requestId: localStorage.get(window, 'requestId'),
        eventType: 'item',
        event: {
          items: params.products.slice(0, 10),
          suggestionUUID: params.id,
          error: params.error,
          requestedAt: params.requestedAt,
          answeredAt: params.answeredAt,
        },
      }),
    },
    true
  );
}
