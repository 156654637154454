import configs from '@/configs';
import { localStorage } from '@/helpers/localStorage';
import type { ITextPrompt } from '@/services/users/redux/types';
import request from '@/utils/request';

export function createMetaData(params: {
  prompt: string;
  chatGPTResponse: any;
  requestedAt: number;
  answeredAt: number;
  textPrompt: ITextPrompt;
}) {
  if (configs.X_API_KEY !== 'undefined' && params.prompt !== '' && window) {
    const { object, objectives, intentions, age, gender, typesOfGifts, price } =
      params.textPrompt;
    return request(
      `${configs.backendUrl}/events`,
      'POST',
      {
        data: JSON.stringify({
          uuid: localStorage.get(window, 'uuid'),
          requestId: localStorage.get(window, 'requestId'),
          eventType: 'request',
          event: {
            object,
            objectives,
            intentions,
            age,
            gender,
            typesOfGifts,
            price,
            prompt: params.prompt,
            rawIdea: params.chatGPTResponse.raw,
            ideas: params.chatGPTResponse.content,
            requestedAt: params.requestedAt,
            answeredAt: params.answeredAt,
          },
        }),
      },
      true
    );
  }
  return false;
}
