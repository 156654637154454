import { combineReducers } from 'redux';

import amazoneItemsReducer from '@/services/amazon/redux/reducer';
import postsReducer from '@/services/posts/redux/reducer';
import usersReducer from '@/services/users/redux/reducer';

const rootReducer = combineReducers({
  users: usersReducer,
  posts: postsReducer,
  amazoneItems: amazoneItemsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
