import type { Method } from 'axios';

import Configs from '@/configs';
import { UnauthorizedError } from '@/exceptions/UnauthorizedError';
import { cookies } from '@/services/auth/cookies';

type IOptions = {
  method: Method;
  headers?: object;
  token?: string;
  apiKey?: string;
};
const requestStream = (
  url: string,
  options: IOptions,
  body: any,
  hasXKey = false
) => {
  const isCSR = typeof window !== 'undefined';
  const axiosExtraParams = { ...options };
  let headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (hasXKey) {
    headers['X-API-KEY'] = `${Configs.X_API_KEY}`;
  }

  if (options.token) {
    headers.Authorization = `Bearer ${options.token}`;
    delete axiosExtraParams.token;
  } else if (options.apiKey) {
    delete axiosExtraParams.token;
  } else if (isCSR) {
    const accessToken = cookies.getAccessToken();
    if (accessToken) {
      headers.Authorization = `Bearer ${cookies.getAccessToken()}`;
    }
  }

  if (options.headers) {
    headers = { ...headers, ...options.headers };
    delete axiosExtraParams.headers;
  }
  return fetch(url, {
    headers,
    method: options.method,
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (response.status === 401) {
      throw new UnauthorizedError();
    } else if (response.status === 403) {
      // throw new ForbiddenError(responseBody.message);
    } else if (response.status === 400) {
      // throw new ValidationError(responseBody.message);
    } else if (response.status > 403) {
      throw new Error('Something went wrong');
    }

    return response;
  });
};

export default requestStream;
