import '../styles/global.scss';

import { isNull } from 'lodash';
import type { AppProps } from 'next/app';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import generateUUID from '@/helpers/generateUUID';
import { localStorage } from '@/helpers/localStorage';
import { wrapper } from '@/redux/store';

import GoogleAnalytics from '../components/GoogleAnalytics';

const MyApp: FC<AppProps> = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      const uuId = localStorage.get(window, 'uuid');
      if (isNull(uuId)) {
        localStorage.set(window, 'uuid', generateUUID());
      }
    }
  }, []);

  return (
    <Provider store={store}>
      <GoogleAnalytics />
      <Component {...props.pageProps} />
      {/* {process.env.NODE_ENV === 'production' && <GoogleAnalytics />} */}
    </Provider>
  );
};

export default MyApp;
