const configs = {
  api: `${process.env.NEXT_PUBLIC_WEB_URL}`,
  backendUrl: `${process.env.NEXT_PUBLIC_BACKEND_URL}`,
  exceptionPath: ['static', '_next'],
  loginPath: '/login',
  registerPath: '/register',
  siteName: 'GiftRaccoon',
  title: 'Your Smart AI Gifting Solution',
  termsTitle: 'Terms and Conditions',
  privacyPolicy: 'Privacy Policy',
  // description:
  //   'Tell GiftRaccoon about your loved ones and get perfect customized gift ideas that are just right for their birthdays, anniversaries, Christmas, weddings, Mother’s Day, Father’s day, Valentine’s Day, and any other occasions!',
  description:
    'Tell GiftRaccoon about your loved ones and get perfect customized gift ideas that are for their birthdays, anniversaries, weddings and any other occasions!',
  locale: 'en',
  chatGPTKey: `${process.env.CHAT_GPT_KEY}`,
  GA_ID: `${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`,
  X_API_KEY: `${process.env.NEXT_PUBLIC_API_KEY}`,
  feedbackLink:
    'https://docs.google.com/forms/d/e/1FAIpQLSfddEa6GGST-Z4O0sUrxkxpp__6mmAHjR_lggZx23kTR2wNFg/viewform',
  sentry: {
    init: {
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      tracesSampleRate: 1.0,
      environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
      enabled: !!`${process.env.NEXT_PUBLIC_SENTRY_DSN}`,
    },
  },
  GOOGLE_CAPTCHA_SITE_KEY: `${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`,
  GOOGLE_CAPTCHA_SECRET_KEY: `${process.env.RECAPTCHA_SECRET_KEY}`,
};

export default configs;
