import type { IUser } from '@/services/users/redux/types';

export const listUsers: IUser[] = [
  {
    id: 1,
    email: 'abc@gmail.com',
  },
  {
    id: 2,
    email: 'def@gmail.com',
  },
];
export const textPrompt = {
  object: '',
  objectives: [],
  intentions: '',
  age: '',
  inputObjectives: '',
  inputTypesOfGifts: '',
  gender: '',
  typesOfGifts: [],
  price: '',
};
