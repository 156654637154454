import type { Method } from 'axios';

import Configs from '@/configs';
import { UnauthorizedError } from '@/exceptions/UnauthorizedError';
import { cookies } from '@/services/auth/cookies';

const request = (
  url: string,
  method: Method,
  options: any = {},
  hasXKey = false
) => {
  const isCSR = typeof window !== 'undefined';
  const axiosExtraParams = { ...options };
  let headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (hasXKey) {
    headers['X-API-KEY'] = `${Configs.X_API_KEY}`;
  }

  if (options.token) {
    headers.Authorization = `Bearer ${options.token}`;
    delete axiosExtraParams.token;
  } else if (options.apiKey) {
    delete axiosExtraParams.token;
  } else if (isCSR) {
    const accessToken = cookies.getAccessToken();
    if (accessToken) {
      headers.Authorization = `Bearer ${cookies.getAccessToken()}`;
    }
  }

  if (options.headers) {
    headers = { ...headers, ...options.headers };
    delete axiosExtraParams.headers;
  }
  // if (options.stream) {
  //   const stream = new ReadableStream({
  //     start(controller) {
  //       function push(event: any) {
  //         if (event.type === 'event') {
  //           const { data } = event;
  //         }
  //       }
  //     },
  //   });
  // }
  return fetch(url, { headers, method, body: options.data || {} }).then(
    async (response) => {
      const responseBody = await response.json();
      if (response.status === 401) {
        throw new UnauthorizedError();
      } else if (response.status === 403) {
        // throw new ForbiddenError(responseBody.message);
      } else if (response.status === 400) {
        // throw new ValidationError(responseBody.message);
      } else if (response.status > 403) {
        throw new Error(responseBody.message || 'Something went wrong');
      }

      return responseBody;
    }
  );
  // return axios({
  //   url,
  //   method,
  //   headers,
  //   ...axiosExtraParams
  // })
  //   .then((response) => {
  //     console.log('response', response?.body);
  //     return response.data;
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     if (error && error.response?.status === 401) {
  //       // Auto redirect
  //       // if (isCSR) {
  //       //   window.location.href = '/';
  //       // }
  //       throw new UnauthorizedError();
  //     } else {
  //       throw new HttpError(error.response.data?.message);
  //     }
  //   });
};

export default request;
