const uuid = 'UUID';
export const localStorage = {
  set: (window: Window, _key: string, _value: any): void => {
    window.localStorage.setItem(_key || uuid, _value);
  },
  get: (window: Window, _key: string = uuid): any => {
    return window.localStorage.getItem(_key);
  },
  delete: (window: Window, _key: string = uuid): any => {
    return window.localStorage.removeItem(_key);
  },
};
